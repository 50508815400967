<template>
  <FondoAnimado />
  <section id="learndativityPrincipal">

    <div class="superior">
      <h1 class="superior__titular">{{ datos.titular.es }}</h1>
      <p class="superior__textos" v-for="texto in datos.textos.es" :key="texto">{{ texto }}</p>
    </div>

    <div class="inferior">
      <div class="juego">
        <div class="botella">
          <img :src="datos.imagenes.fondo_peque" alt="fondo peque botella" class="botella__fondoGrande">
          <img :src="datos.imagenes.fondo_grande" alt="fondo grande botella" class="botella__fondoPeque">
          <div class="botella__contenedor">
            <img :src="datos.imagenes.botella" alt="imagen botella" class="botella__imagen">
            <CargaLiquido />
            <p class="botella__texto"><span class="numero">0</span>%</p>
          </div>
        </div>
        <!--botella-->

        <div class="cargador">
          <div class="cargador__frontal">
            <div class="cargador__barra">
              <span class="cargador__cargando"></span>
            </div>
            <p class="cargador__texto">CARGANDO...</p>
          </div>
          <div class="cargador__oculta">
            <p class="cargador__texto_por">{{textosPorcentajes}}</p>
          </div>
        </div>
        <!--cargador-->
      </div>
      <!--juego-->

      <div class="preguntas">
        <form id="formulario_preguntas" action="" class="preguntas__form" name="formulario_preguntas">
          <div class="preguntas__contenedor" name="preguntas" :style="`left:-${numeroPreguntas}00%`" 
            v-for="(preguntas, index_1) in preguntasAle" :key="index_1">
            <div class="preguntas__bloques" v-for="(textos, index_2) in preguntas" :key="index_2">
              <div class="preguntas__check">
                <input class="no input" type="radio" :id="`no_${index_2}_${index_1}`"
                  :name="`pregunta_${index_2}_${index_1}`" checked>
                <label :for="`no_${index_2}_${index_1}`"></label>

                <input class="si input" type="radio" :id="`si_${index_2}_${index_1}`"
                  :name="`pregunta_${index_2}_${index_1}`">
                <label :for="`si_${index_2}_${index_1}`"></label>

                <span class="marcador" @click="marcador($event.target)"></span>
              </div>
              <p class=preguntas__texto :id="`preguntas__texto_${index_2}_${index_1}`">{{ textos.texto }}</p>
            </div>
          </div>
        </form>

        <!-- @click="() => {if(numeroPreguntas < preguntasAle.length - 1) { numeroPreguntas++; finalPreguntas = false; } else { finalPreguntas = true} }" -->
        <div  v-if="!pulsarBoton" class="flechas">
          <span class="flechas__izquierda material-icons" 
            @click="numeroPreguntas > 0 ? numeroPreguntas-- : ''"
            v-if="numeroPreguntas > 0">chevron_left</span>
          <span class="flechas__derecha material-icons"
            @click="numeroPreguntas < preguntasAle.length - 1 ? numeroPreguntas++ : ''"
            v-show="numeroPreguntas < preguntasAle.length - 1">navigate_next</span>
        </div>

        <div v-if="!pulsarBoton" class="contenedor_boton" v-show="numeroPreguntas >= preguntasAle.length - 1 && !pulsarBoton">
          <button class="boton" id="buttonCompartirLearndativity" @click="calcularLearndativity()"><span class="calcularMi">{{ datos.boton.es[0] }}&nbsp;</span>{{ datos.boton.es[1] }}</button>
        </div>
        
        <div @click="modal()" v-if="pulsarBoton" class="compartirLearndativity">
          <button class="compartirLearndativity__boton">
            <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40">
              <path d="M30.208 36.667q-2.041 0-3.437-1.417t-1.396-3.417q0-.291.042-.666.041-.375.166-.667L13.25 23.333q-.667.667-1.562 1.084-.896.416-1.855.416-2 0-3.416-1.416Q5 22 5 20q0-2 1.417-3.417 1.416-1.416 3.416-1.416.959 0 1.834.375.875.375 1.583 1.041l12.333-7.125q-.125-.25-.166-.604-.042-.354-.042-.687 0-2 1.396-3.417 1.396-1.417 3.437-1.417 2 0 3.417 1.417 1.417 1.417 1.417 3.417 0 2-1.417 3.416Q32.208 13 30.208 13q-1 0-1.875-.333-.875-.334-1.541-1l-12.334 6.958q.084.292.146.687.063.396.063.688 0 .292-.063.625-.062.333-.146.625l12.334 7.042q.666-.584 1.52-.938.855-.354 1.896-.354 2 0 3.417 1.417 1.417 1.416 1.417 3.416t-1.417 3.417q-1.417 1.417-3.417 1.417Zm0-26.459q.875 0 1.459-.583.583-.583.583-1.458t-.583-1.459q-.584-.583-1.459-.583-.875 0-1.479.583-.604.584-.604 1.459 0 .875.604 1.458t1.479.583ZM9.833 22.042q.875 0 1.459-.584.583-.583.583-1.458t-.583-1.458q-.584-.584-1.459-.584-.875 0-1.458.584-.583.583-.583 1.458t.583 1.458q.583.584 1.458.584Zm20.375 11.833q.875 0 1.459-.583.583-.584.583-1.459 0-.875-.583-1.458-.584-.583-1.459-.583-.875 0-1.479.583-.604.583-.604 1.458t.604 1.459q.604.583 1.479.583Zm0-25.708ZM9.833 20Zm20.375 11.833Z"/>
            </svg>
            Comparte tu	&nbsp;
            <p>nivel de Learndativity</p>
          </button>
          <!-- <p>https://pharma.alebateducation.com/learndativity/{{this.params}}</p> -->
        </div>        
      </div>
      <!--preguntas-->
      <a href="/" class="inferior__home">¿Qué es learndativity?</a>
    </div>
    <div class="fondoOpacado" id="fondoOpacado" @click="modal()"></div>
    <div class="conjuntoCompartir" id="conjuntoCompartir">
      <div class="modalCompartir" id="modalCompartir">
        <div class="modalCompartir__titulo">
          <p>Compartir Learndativity</p>
          <p class="cierre" @click="modal()">X</p>
        </div>
        <div class="modalCompartir__formulario">
          <form action='https://forms.zohopublic.com/alebateducation/form/learndativity/formperma/N_8VWdjRfRdFjHSHM4vSHs4ABAHQj6WY089t52YK8Pg/htmlRecords/submit' name='form' id='form' method='POST' accept-charset='UTF-8' enctype='multipart/form-data' @keydown.enter="">
            <input type="hidden" name="zf_referrer_name" value=""><!-- To Track referrals , place the referrer name within the " " in the above hidden input field -->
            <input type="hidden" name="zf_redirect_url" value=""><!-- To redirect to a specific page after record submission , place the respective url within the " " in the above hidden input field -->
            <input type="hidden" name="zc_gad" value=""><!-- If GCLID is enabled in Zoho CRM Integration, click details of AdWords Ads will be pushed to Zoho CRM -->
            <input
            id="pharmaMail"
            type="text"
            name="SingleLine"
            value=""
            fieldType=1
            maxlength="255"
            placeholder="ENVIAR EMAIL"
            @keyup="validarEmail"
            @keypress="validarEmail"
            @keydown="validarEmail">
            <textarea
            name="MultiLine"
            maxlength="65535"
            placeholder="" 
            v-model="preguntasMarcadas"></textarea>
            <button type="submit" id="botonPharma" v-show="emailValido" :disabled="emailValido==false">ENVIAR</button>
          </form>
        </div>
        <div class="modalCompartir__iconos">
          <div @click="copiarUrl()">
            <div class="compartir">
              <p class="compartir-texto">&lsaquo;&rsaquo;</p>
            </div>
            Copiar URL
          </div>
          <div class="whatsapp" @click="enlaceWhatsapp()">
            <svg viewBox="3 3 24 24">
              <path d="M15 3C8.373 3 3 8.373 3 15c0 6.627 5.373 12 12 12s12-5.373 12-12C27 8.373 21.627 3 15 3zM20.924 19.143c-.247.693-1.461 1.363-2.005 1.41-.549.051-1.061.247-3.568-.74-3.024-1.191-4.931-4.289-5.08-4.489-.149-.195-1.21-1.61-1.21-3.07 0-1.465.768-2.182 1.037-2.48.274-.298.595-.372.795-.372.195 0 .395 0 .568.009.214.005.447.019.67.512.265.586.842 2.056.916 2.205.074.149.126.326.023.521-.098.2-.149.321-.293.498-.149.172-.312.386-.447.516-.149.149-.302.312-.13.609s.768 1.27 1.651 2.056c1.135 1.014 2.093 1.326 2.391 1.475s.47.126.642-.074c.177-.195.744-.865.944-1.163.195-.298.395-.247.665-.149.274.098 1.735.819 2.033.968s.493.223.568.344C21.171 17.854 21.171 18.449 20.924 19.143z"/>
            </svg>
            Whatsapp
          </div>
          <div class="linkedin" @click="enlaceLinkedin()">
            <svg viewBox="0 0 512 512">
              <path d="M186.4 142.4c0 19-15.3 34.5-34.2 34.5 -18.9 0-34.2-15.4-34.2-34.5 0-19 15.3-34.5 34.2-34.5C171.1 107.9 186.4 123.4 186.4 142.4zM181.4 201.3h-57.8V388.1h57.8V201.3zM273.8 201.3h-55.4V388.1h55.4c0 0 0-69.3 0-98 0-26.3 12.1-41.9 35.2-41.9 21.3 0 31.5 15 31.5 41.9 0 26.9 0 98 0 98h57.5c0 0 0-68.2 0-118.3 0-50-28.3-74.2-68-74.2 -39.6 0-56.3 30.9-56.3 30.9v-25.2H273.8z"/>
            </svg>
            Linkedin
          </div>
        </div>
      </div>
      <div v-show="mensaje" class="enlaceCopiado" id="enlaceCopiado">
        <p>¡Enlace copiado!</p>
      </div>
    </div>
    <!--inferior-->
  </section>
  <CustomCursor />
</template>
<script>
import { gsap, TextPlugin } from "gsap/all";
import { ref, defineAsyncComponent } from 'vue';
gsap.registerPlugin(TextPlugin);

export default {
  name: "LearndativityPrincipal",
  props: {
    datos: Object,
  },
  data(){
    return{
      params : this.$route.params.params,
      calculo : 0,
      pulsarBoton : false,
      siInput : null,
      mensaje  : false,
      preguntasMarcadas: [],
      emailValido : false
    }
  },
  components: {
    FondoAnimado: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "FondoAnimado" */ "./componentsInternos/FondoAnimado.vue"
      )
    ),
    CargaLiquido: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "CargaLiquido" */ "./componentsInternos/CargaLiquido.vue"
      )
    ),
    CustomCursor: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "CustomCursor" */ "../customCursor/CustomCursor.vue"
      )
    ),
  },
  setup(props) {
    const numeroPreguntas = ref(0);
    const datos = props.datos;//datos
    const textosPorcentajes = ref(datos.porcentajes.es[0]);
    let preguntasAle = [[]];
    let cnt = 0;
    let valorTotal = 0;
    let valores = [];
    let numero = Math.floor(Math.random() * 2); 
    let finalPreguntas = ref(false);
    let porcentajeLearndativity = '...';

    //ordena la array de forma aleatoria
    const arrayPreguntas = datos.preguntas[numero].es.slice().sort(() => Math.random() - 0.5);

    arrayPreguntas.forEach((element, index) => {
      valorTotal += parseInt(element.si) + parseInt(element.no);
      valores.push(element.no);
      valores.push(element.si);
      preguntasAle[cnt].push(element);
      if ((index + 1) % 4 == 0) {
        cnt++;
        preguntasAle[cnt] = [];
      }
    });

    return { datos, numeroPreguntas, preguntasAle, valorTotal, valores,textosPorcentajes }
  },
  mounted() {
    setTimeout(() => { this.posicionarFondo() }, 200);
    window.onscroll = () => {
      this.posicionarFondo();
    }
    window.addEventListener('resize', () => {
      this.posicionarFondo();
    })
  },
  methods: {
    marcador(event) {
      var id = event.previousSibling.getAttribute("for");
      var check = document.getElementById(id)
      check.checked = !check.checked
    },
    posicionarFondo() {
      const tamanio = document.querySelector('.fondoAnimado__sol').getBoundingClientRect().height;
      const posicion = document.querySelector('.botella').getBoundingClientRect().bottom - tamanio;
      gsap.set('.fondoAnimado', { top: `calc(-100% + ${posicion}px)` })
    },
    calcularLearndativity() {
      this.pulsarBoton = true;
      let valorMarcados = 0;
      var seleccion = document.getElementsByClassName('input');

      /**Desactivar preguntas**/
      const bloquesPreguntas = document.querySelectorAll('.preguntas__contenedor');
      bloquesPreguntas.forEach((element,index)=>{
        element.classList.add('desactivar')
      })

      this.sacarPreguntas()
      /*****************************/  

      Array.from(seleccion).forEach((element, index) => {
        if (element.checked) {
          valorMarcados += parseInt(this.valores[index]);
        }
      });
      gsap.to('.cargador__frontal',{rotationY:0,duration:.7})
      gsap.to('.cargador__oculta',{rotationY:180,duration:.7})

      var porcentaje = Math.round((valorMarcados / this.valorTotal) * 100);
      this.porcentajeLearndativity = porcentaje;
      gsap.set(".cargador__cargando", { width: `0%` });
      gsap.to('.cargador__cargando', {
        width: '100%', duration: 3,
        onComplete: () => {
          
          if(porcentaje <= 28){
            this.textosPorcentajes = this.datos.porcentajes.es[0]
          }else if(porcentaje > 28 && porcentaje <= 47){
            this.textosPorcentajes = this.datos.porcentajes.es[1]
          }else if(porcentaje > 47 && porcentaje <= 53){
            this.textosPorcentajes = this.datos.porcentajes.es[2]
          }else{
            this.textosPorcentajes = this.datos.porcentajes.es[3]
          }
          
          gsap.to('.cargador__frontal',{rotationY:180,duration:.7})
          gsap.to('.cargador__oculta',{rotationY:0,duration:.7})

          var counter = { var: 0 };
          gsap.to(counter, {
            var: porcentaje, duration: 3,
            onUpdate: () => {
              var number = Math.ceil(counter.var);
              document.getElementsByClassName('numero')[0].textContent = number;
              gsap.set(".water", { opacity: 1 });
              gsap.set(".wave", { height: `${number}%` });
            }
          });
        }
      });
    },
    modal(){
      const modal = document.getElementById('conjuntoCompartir')
      const fondoOpacado = document.getElementById('fondoOpacado')
      const learndativityPrincipal = document.getElementById('learndativityPrincipal')
      if(fondoOpacado.style.display == "" || fondoOpacado.style.display=='none'){
        if(modal.classList.contains('bounce-leave-active')){
          modal.classList.remove('bounce-leave-active')
          modal.classList.add("bounce-enter-active")
          learndativityPrincipal.style.overflowX = 'clip'
        }else{
          modal.classList.add("bounce-enter-active")
          learndativityPrincipal.style.overflowX = 'clip'
        }
        fondoOpacado.style.display = 'block'
      }else{
        if(modal.classList.contains('bounce-enter-active')){
          modal.classList.remove('bounce-enter-active')
          modal.classList.add("bounce-leave-active")
        }else{
          modal.classList.add("bounce-leave-active")
        }
        fondoOpacado.style.display = 'none'
      }
    },
    copiarUrl(){
      this.mensaje = true
      const pharma = 'https://pharma.alebateducation.com/learndativity/'
      const aux = this.params == undefined ? pharma: pharma+this.params
      navigator.clipboard.writeText(aux)
      const enlaceCopiado = document.getElementById('enlaceCopiado')
      enlaceCopiado.classList.add('animacionEnlace')
      const eliminarClase = setTimeout(()=>{enlaceCopiado.classList.remove('animacionEnlace');this.mensaje=false}, 1500)
    },
    enlaceWhatsapp(){
      const whatsapp =`https://api.whatsapp.com/send?text=Mi puntación LEARNDATIVITY es ${this.porcentajeLearndativity}%%20¿Sabes qué es LEARNDATIVITY?%20¿Quieres conocer tu nivel?%20Entra aquí%20https://pharma.alebateducation.com/learndativity/`
      window.open(whatsapp)
    },
    enlaceLinkedin(){
      const linkedin = 'http://www.linkedin.com/shareArticle?url=https://pharma.alebateducation.com'
      window.open(linkedin);
      
    },
    sacarPreguntas(){

    this.siInput = document.querySelectorAll('.si');

    this.siInput.forEach((element) =>{
      if(element.checked){
        element = element.id
        element = element.slice(3)
        const frase = document.getElementById(`preguntas__texto_${element}`).innerText
        this.preguntasMarcadas.push(frase)
      }
    })
    /*********************************************/
    this.preguntasMarcadas = this.preguntasMarcadas.join('||')
    },
    validarEmail(){
      const inputemail = ref(document.querySelector(
          "#pharmaMail"
        ));
        const validaremail =
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (validaremail.test(inputemail.value.value) && inputemail.value.value != "") {
          this.emailValido = true;
        }else{
          this.emailValido = false;
        }
    }
  }
};
</script>